/* global tns, scrollIt, Waypoint */

// Closest Polyfill

if (!Element.prototype.matches)
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
                                Element.prototype.webkitMatchesSelector;

if (!Element.prototype.closest)
  Element.prototype.closest = function(s) {
    var el = this;
    if (!document.documentElement.contains(el)) return null;
    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
  } while (el !== null && el.nodeType === 1);
  return null;
};


// Native validation formfields

(function() {
  var formfields = document.querySelectorAll('input[type=text], input[type=number], input[type=tel], input[type=email], textarea');
  if(formfields ) {
    for (var i = 0; i < formfields.length; i++) {
      formfields[i].addEventListener('blur', function(event) {
        event.target.parentNode.classList.add('interacted');
      }, false);
    }
  }
})();


// Scroll to links

(function () {
  var scrolldownButton = document.querySelectorAll('.js-scrolldown');

  if(scrolldownButton) {

    for (var i = 0; i < scrolldownButton.length; i++) {

      scrolldownButton[i].addEventListener('click', function(e) {

        var scrollToDiv = document.querySelector(this.dataset.scrollto);
        scrollIt(scrollToDiv, 300, 'easeOutQuad');
        e.preventDefault();
      });
    }
  }
})();


// Toggle product variations (table)

(function () {

  var products = document.querySelectorAll('.js-product');

  if(products) {
    for (var i = 0; i < products.length; i++) {

      var toggleLink = products[i].querySelector('.js-show-variations');

      if(toggleLink) {
        toggleLink.addEventListener('click', function(e){

          var toggleTarget = this.closest('.js-product').querySelector('.js-variations');

          if(toggleTarget) {
            toggleTarget.classList.toggle('hide');
          }

          e.preventDefault();
        });
      }
    }
  }

})();


// Toggle sidebar filters

(function () {
  var filtergroup = document.querySelectorAll('.filter');

  if(filtergroup) {
    for (var i = 0; i < filtergroup.length; i++) {
      var togglefilter = filtergroup[i].querySelector('.js-toggle-filter');
      var showmore = filtergroup[i].querySelector('.js-show-more');
      var showless = filtergroup[i].querySelector('.js-show-less');

      if(togglefilter){
        togglefilter.addEventListener('click', function(e) {
          e.target.parentNode.classList.toggle('js-close');
          e.preventDefault();
        });
      }

      if(showmore){
        showmore.addEventListener('click', function(e) {
          e.target.parentNode.classList.add('js-show-list');
          e.preventDefault();
        });
      }

      if(showless){
        showless.addEventListener('click', function(e) {
          e.target.parentNode.classList.remove('js-show-list');
          e.preventDefault();
        });
      }
    }
  }
})();


// Carousel

(function() {
  var carousel3 = document.querySelectorAll('.carousel--3');
  if (carousel3) {
    for (var i = 0; i < carousel3.length; i++) {
      tns({
        container: carousel3[i],
        items: 1,
        slideBy: 1,
        edgePadding: 30,
        autoplay: false,
        autoplayTimeout: 6000,
        autoplayButtonOutput: false,
        mouseDrag: true,
        controls: false,
        controlsText: ['', ''],
        nav: true,
        gutter: 10,
        responsive: {
          480: {
            gutter: 20,
            edgePadding: 60
          },
          800: {
            gutter: 20,
            items: 2,
            edgePadding: 0
          },
          960: {
            gutter: 20,
            items: 3,
            edgePadding: 0
          }
        }
      });
    }
  }
})();

(function() {
  var carousel4 = document.querySelectorAll('.carousel--4');
  if (carousel4) {
    for (var i = 0; i < carousel4.length; i++) {
      tns({
        container: carousel4[i],
        items: 1,
        slideBy: 1,
        edgePadding: 30,
        autoplay: false,
        autoplayTimeout: 6000,
        autoplayButtonOutput: false,
        mouseDrag: true,
        controls: false,
        controlsText: ['', ''],
        nav: true,
        gutter: 10,
        responsive: {
          480: {
            gutter: 20,
            edgePadding: 60
          },
          600: {
            gutter: 20,
            items: 2,
            edgePadding: 40
          },
          800: {
            gutter: 20,
            items: 3,
            edgePadding: 0
          },
          960: {
            gutter: 20,
            items: 4,
            edgePadding: 0
          }
        }
      });
    }
  }
})();

(function () {
  var certs = document.querySelectorAll('.certs');
  if (certs) {
    for (var i = 0; i < certs.length; i++) {
      tns({
        container: certs[i],
        items: 2,
        slideBy: 1,
        edgePadding: 0,
        autoplay: true,
        autoplayTimeout: 6000,
        autoplayButtonOutput: false,
        mouseDrag: true,
        controls: false,
        controlsText: ['', ''],
        nav: true,
        autoHeight: true,
        gutter: 10,
        responsive: {
          480: {
            gutter: 20,
            items: 3
          },
          600: {
            items: 4
          },
          800: {
            items: 6
          }
        }
      });
    }
  }
})();


// Show/Hide filters on mobile

(function() {
  var togglefilterbutton = document.querySelector('.js-showfilters');

  if(togglefilterbutton){
    togglefilterbutton.addEventListener('click', function(e) {
      document.querySelector('.filters').classList.toggle('show');

      if (this.textContent == 'Toon filters') {
        this.textContent = 'Verberg filters';
      } else {
        this.textContent = 'Toon filters';
      }

      e.preventDefault();
    });
  }
})();


// Increase / Decrease Input Number fields

(function () {
  var amount = document.querySelectorAll('.amount');

  if(amount) {

    for (var i = 0; i < amount.length; i++) {

      var increaseButton = amount[i].querySelector('.js-increase');
      var decreaseButton = amount[i].querySelector('.js-decrease');

      increaseButton.addEventListener('click', function(e) {

        var amountField = this.closest('.amount').querySelector('.js-amount');
        var value = parseInt(amountField.value, 10);

        value = isNaN(value) ? 0 : value;
        value++;
        amountField.value = value;
        e.preventDefault();
      });

      decreaseButton.addEventListener('click', function(e) {

        var amountField = this.closest('.amount').querySelector('.js-amount');
        var value = parseInt(amountField.value, 10);

        value = isNaN(value) ? 0 : value;
        value < 2 ? value = 2 : '';
        value--;
        amountField.value = value;
        e.preventDefault();
      });
    }
  }
})();


// Product-gallery

(function() {
  var gallery = document.querySelector('.gallery');
  if (gallery) {
    tns({
      container: '.gallery',
      items: 1,
      navContainer: '.gallery__thumbnails',
      navAsThumbnails: true,
      autoplay: false,
      autoplayTimeout: 1000,
      autoHeight: true,
      mouseDrag: true,
      controls: false,
      autoplayButton: '#gallery-toggle',
      speed: 400,
      arrowKeys: true
    });
  }
})();


// Navigation - shop menu

(function () {

  var navshop = document.querySelector('.nav--shop');
  var navshopitems = document.querySelectorAll('.nav--shop__item');

  if(navshop) {

    for (var i = 0; i < navshopitems.length; i++) {

      var shoplink = navshopitems[i].querySelector('.nav--shop__link');

      if(shoplink) {
        shoplink.addEventListener('click', function(e){

          var items = document.querySelectorAll('.js-show-submenu');
          var currentItem = this.closest('.nav--shop__item');


          // VOOR MOBIEL EN TABLET
          // Mogelijkheid om item ook in te klappen
          if (document.body.clientWidth < 769) {

            if (currentItem.classList.contains('js-show-submenu')) {
              currentItem.classList.remove('js-show-submenu');

            } else {
              for (var i = 0; i < items.length; i++) {
                items[i].classList.remove('js-show-submenu');
              }
              currentItem.classList.add('js-show-submenu');
            }

          } else {

            for (i = 0; i < items.length; i++) {
              items[i].classList.remove('js-show-submenu');
            }

            currentItem.classList.add('js-show-submenu');
          }

          updateMenuHeight();

          e.preventDefault();
        });
      }
    }
  }

})();


// Toggle Mainmenu

(function () {

  var body = document.body;
  var toggleMenu = document.querySelector('.js-togglemenu');
  var closeMenu = document.querySelector('.menu-overlay');
  var selectedItem = document.querySelector('.js-show-submenu');

  if(toggleMenu) {
    toggleMenu.addEventListener('click', function(e){
      body.classList.toggle('show-menu');

      // Bij openen menu alle submenu's verbergen op kleine schermen
      if (document.body.clientWidth < 601) {
        if(selectedItem) {
          selectedItem.classList.remove('js-show-submenu');
        }
      }

      updateMenuHeight();
      e.preventDefault();
    });
  }

  if(closeMenu) {
    closeMenu.addEventListener('click', function(e){
      body.classList.remove('show-menu');
      e.preventDefault();
    });
  }

})();


// Close all modals/lightboxes/overlays

(function () {

  var body = document.body;
  var closeButton = document.querySelector('.js-close');

  if(closeButton) {
    closeButton.addEventListener('click', function(e){
      body.classList.remove('show-menu', 'show-account', 'show-search');

      e.preventDefault();
    });
  }

})();


// Sticky header

function resizeStickyHeader() {
  if (!document.body.classList.contains('js-stickyheader')) {

    var header = document.querySelector('.js-header');
    var site = document.querySelector('.site');
    var headerHeight = header.offsetHeight;

    setTimeout(function() {
      site.style.paddingTop = headerHeight + 'px';
    }, 100);
  }
}

(function () {

  var header = document.querySelector('.js-header');
  var site = document.querySelector('.site');

  if(header) {

    window.addEventListener('resize', resizeStickyHeader);
    resizeStickyHeader();

    new Waypoint({
      element: site,
      handler: function() {
        header.classList.toggle('js-sticky');
        document.body.classList.toggle('js-stickyheader');
      },
      offset: -header.clientHeight
    });
  }

})();


// Toggle account/login

(function () {

  var body = document.body;
  var toggleAccount = document.querySelector('.js-account-link');

  if(toggleAccount) {
    toggleAccount.addEventListener('click', function(e){
      body.classList.toggle('show-account');

      e.preventDefault();
    });
  }

})();


// Update Menu Height

function updateMenuHeight() {
  if (document.querySelector('.nav--shop--sub')){
    var startMenuHeight = document.querySelector('.nav--shop--sub').offsetHeight;
    document.querySelector('.nav--shop').style.minHeight = startMenuHeight + 'px';
  }
}

(function () {
  window.addEventListener('resize', updateMenuHeight);
})();


// Toggle search

(function () {

  var body = document.body;
  var toggleSearch = document.querySelectorAll('.js-searchlink');

  if(toggleSearch) {

    for (var i = 0; i < toggleSearch.length; i++) {
      toggleSearch[i].addEventListener('click', function(e){
        body.classList.toggle('show-search');

        e.preventDefault();
      });
    }
  }

})();

// Edit shoppingcart

(function () {

  var cartEditLink = document.querySelector('.js-cartedit');
  var cart = document.querySelector('.js-cart');


  if(cartEditLink) {
    cartEditLink.addEventListener('click', function(e){
      if(cart) {
        cart.classList.toggle('cart--edit');
      }
      e.preventDefault();
    });
  }

})();

// Add product to shoppingcart

(function () {

  var body = document.body;
  var additemLink = document.querySelectorAll('.js--add-item');

  if(additemLink) {

    for (var i = 0; i < additemLink.length; i++) {

      additemLink[i].addEventListener('click', function(e){

        body.classList.add('js-item-added');
        e.preventDefault();
      });
    }
  }
})();

// Close order modal

(function () {

  var body = document.body;
  var closeModal = document.querySelector('.js--close-modal');

  if(closeModal) {
    closeModal.addEventListener('click', function(e){

      body.classList.remove('js-item-added');
      e.preventDefault();
    });
  }
})();


// Close account modal

(function () {

  var body = document.body;
  var closeAccount = document.querySelector('.js-close-account');

  if(closeAccount) {
    closeAccount.addEventListener('click', function(e){

      body.classList.remove('show-account');
      e.preventDefault();
    });
  }
})();
